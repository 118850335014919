/* footer */

.nd-footer {
  background-color: #222222;
  padding: 52px 0 54px 0;
  box-sizing: border-box;
}

.nd-footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-footer-top-box {
  display: flex;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #929092;
}

.nd-footer-top-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #ffffff;
}

.nd-footer-top-text-box {
  margin-left: 20px;
}

.nd-footer-top-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #929092;
}

.footer-bottom-box {
  margin-top: 20px;
}

.nd-footer-bottom-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #8c8a8c;
}

@media all and (max-width: 1024px) {
  .nd-footer {
    padding: 15px 16px;
  }

  .nd-footer-top-title,
  .nd-footer-top-text {
    font-size: 10px;
  }

  .nd-footer-top-text-box {
    margin-left: 10px;
  }

  .nd-footer-bottom-text {
    font-size: 10px;
  }

  .nd-mobile-footer-text-box {
    margin-bottom: 15px;
  }
}
