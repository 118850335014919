/* 두번째꺼 */

.nd-main-bg {
  /* background-image: url('/images/main-bg.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 73px;
  min-height: calc(100vh);
  position: relative;
}

.nd-main-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.nd-main-slide-box {
  position: absolute;
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  overflow: hidden;
}

.nd-main-box {
  padding: 300px 0;
  box-sizing: border-box;
}

.nd-main-top-box {
  margin-bottom: 30px;
}

.nd-main-top-text {
  font-size: 25px;
  line-height: 2.4;
  color: #ffffff;
  white-space: pre-wrap;
}

.nd-main-bottom-text {
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  color: #ffffff;
  white-space: pre-wrap;
}

.nd-main-map-text.address {
  max-width: 240px;
}

/* content */

.nd-content-cotainer {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nd-content-title-box {
  margin: 84px 0 42px 0;
}

.nd-content-title {
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  color: #000000;
}

.nd-content-text-box {
  margin-bottom: 50px;
}

.nd-content-text {
  text-align: center;
  font-size: 25px;
  line-height: 1.4;
  color: #000000;
}

.nd-content-company-box {
  margin-bottom: 58px;
}

.nd-content-top-company-box {
  padding: 48px 250px 48px 250px;
  box-sizing: border-box;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
}

.nd-content-top-left-company-box {
  display: flex;
  align-items: center;
}

.nd-content-top-left-company-title-box {
  padding: 0 50px;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
  width: 240px;
}

.nd-content-top-left-company-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  color: #121212;
}

.nd-content-top-right-company-box {
  display: flex;
  align-items: center;
}

.nd-content-top-right-company-title {
  font-size: 25px;
  line-height: 1.4;
  color: #cccccc;
}

.nd-content-bottom-company-box {
  padding: 48px 250px 48px 250px;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
}

.nd-content-bottom-left-company-box {
  display: flex;
  align-items: center;
}

.nd-content-bottom-left-company-title-box {
  padding: 0 40px;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
  width: 240px;
}

.nd-content-bottom-left-company-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  color: #121212;
}

.nd-content-bottom-right-company-title-box {
  margin-left: 50px;
}

.nd-content-bottom-right-company-title {
  font-size: 25px;
  line-height: 1.4;
  color: #cccccc;
}

.nd-content-company-logo-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 132px;
}

.nd-content-inner {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-content-inner-title-box {
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}

.nd-content-inner-title {
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  color: #000000;
}

.nd-content-item-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 84px;
}

.nd-content-first-item-box::before,
.nd-content-middle-item-box::before,
.nd-content-last-item-box::before {
  content: '';
  display: inline-block;
  width: 35px;
  height: 35px;
  background-image: url('/images/cross.svg');
  background-size: cover;
  background-repeat: no-repeat;
  transition: 0.2s all ease-in-out;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.nd-content-first-item:hover .nd-content-first-item-box::before,
.nd-content-middle-item:hover .nd-content-middle-item-box::before,
.nd-content-last-item:hover .nd-content-last-item-box::before {
  opacity: 1;
}

.nd-content-first-item:hover,
.nd-content-middle-item:hover,
.nd-content-last-item:hover {
  background-color: #38393b;
}

.nd-content-first-item {
  position: relative;
  width: 30%;
  background-color: rgba(67, 69, 72, 1);
  background-image: url('/images/raw-material.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 346px 44px 68px 44px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.nd-content-middle-item {
  position: relative;
  width: 30%;
  background-color: rgba(67, 69, 72, 1);
  background-image: url('/images/resin.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 346px 44px 68px 44px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.nd-content-last-item {
  position: relative;
  width: 30%;
  background-color: rgba(67, 69, 72, 1);
  background-image: url('/images/dev-products.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 346px 44px 68px 44px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.nd-content-item-box-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  color: #ffffff;
}

.nd-content-item-box-text {
  font-size: 20px;
  line-height: 3;
  color: #ffffff;
  white-space: nowrap;
}

/* 맵맵맵맵맵!! */

.nd-map-box {
  margin-bottom: 94px;
  position: relative;
}

.nd-main-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
}

.nd-main-map iframe {
  left: 0;
  top: 0;
  height: calc(100% + 150px);
  width: 100%;
  position: absolute;
}

.nd-main-map-box {
  position: absolute;
  top: 0;
  background-color: #5c7cfa;
  display: flex;
  padding: 50px 30px 30px 50px;
  box-sizing: border-box;
}

.nd-main-map-title-box {
  margin-bottom: 20px;
}

.nd-main-map-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
  color: #ffffff;
}

.nd-nd-main-map-text-box {
  margin-bottom: 20px;
}

.nd-main-map-text {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #ffffff;
}

.nd-main-map-detail-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 58px;
}

.nd-main-map-detail {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #bbc9fd;
}

.nd-main-map-img {
  margin-left: 5px;
}

@-webkit-keyframes scrollDownPointer {
  60% {
    /* -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%); */
    top: 20px;
  }
  70% {
    opacity: 0;
  }
  100% {
    /* -webkit-transform: translate(0, 0);
    transform: translate(0, 0); */
    top: 12px;
  }
}
@keyframes scrollDownPointer {
  60% {
    /* -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%); */
    top: 20px;
  }
  70% {
    opacity: 0;
  }
  100% {
    /* -webkit-transform: translate(0, 0);
    transform: translate(0, 0); */
    top: 12px;
  }
}
.mouse-down-bg {
  position: absolute;
  bottom: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.mouse-down {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 32px;
  content: '';
  display: inline-block;
  width: 25px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/images/mouse-border.png');
}

.mouse-down::before {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  display: inline-block;
  width: 10px;
  height: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/images/mouse-down.png');
  -webkit-animation: scrollDownPointer 1.5s infinite;
  animation: scrollDownPointer 1.5s infinite;
}

.mouse-down-desc {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.2px;
  text-align: center;
}

@media all and (max-width: 1024px) {
  .nd-main-map-text.address {
    max-width: 190px;
  }

  .nd-main-box {
    padding: 130px 40px;
  }

  .nd-main-top-box {
    margin-bottom: 8px;
  }

  .nd-main-top-text {
    font-size: 21px;
    line-height: 1;
  }

  .nd-main-bottom-text {
    font-size: 21px;
    font-weight: 800;
  }

  .nd-content-title-box {
    margin: 30px 0 12px 0;
  }

  .nd-content-title {
    font-size: 20px;
    font-weight: 800;
  }

  .nd-content-text-box {
    margin-bottom: 30px;
  }

  .nd-content-text {
    font-size: 15px;
  }

  .nd-content-top-company-box,
  .nd-content-bottom-company-box {
    padding: 0 80px 0 80px;
    border: 0;
    flex-direction: column;
    align-items: center;
  }

  .nd-content-top-company-box {
    margin-bottom: 16px;
  }

  .nd-content-company-img {
    width: 23px;
    height: 21px;
  }

  .nd-content-top-left-company-box,
  .nd-content-bottom-left-company-box {
    flex-direction: column;
    margin-top: 10px;
  }

  .nd-content-top-left-company-title-box,
  .nd-content-bottom-left-company-title-box {
    padding: 0;
    border: 0;
    margin-top: 6.5px;
  }

  .nd-content-top-left-company-title,
  .nd-content-bottom-left-company-title {
    font-size: 13px;
  }

  .nd-content-top-right-company-box {
    align-items: center;
  }

  .nd-content-top-right-company-logo {
    width: 75px;
    height: 23px;
  }

  .nd-content-bottom-right-company-title-box {
    margin: 0;
  }

  .nd-content-top-right-company-title,
  .nd-content-bottom-right-company-title {
    font-size: 10px;
  }

  .nd-content-company-logo-box {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 40px;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 22px;
  }

  .nd-content-company-logo-img:nth-child(1) {
    width: 90px;
    height: 12px;
  }

  .nd-content-company-logo-img:nth-child(2) {
    width: 66px;
    height: 14px;
  }

  .nd-content-company-logo-img:nth-child(3) {
    width: 50px;
    height: 25px;
  }

  .nd-content-company-logo-img:nth-child(4) {
    width: 60px;
    height: 15px;
  }

  .nd-content-company-logo-img:nth-child(5) {
    width: 80px;
    height: 30px;
  }

  .nd-content-company-logo-img:nth-child(6) {
    width: 100px;
    height: 14px;
  }

  .nd-content-inner-title-box {
    margin-bottom: 45px;
  }

  .nd-content-inner-title {
    font-size: 20px;
  }

  .nd-content-item-box {
    align-items: center;
    flex-direction: column;
  }

  .nd-content-first-item {
    width: calc(100% - 110px);
    padding: 115px 14px 22px 14px;
    margin-bottom: 15px;
  }

  .nd-content-middle-item {
    width: calc(100% - 110px);
    padding: 115px 14px 40px 14px;
    margin-bottom: 15px;
  }

  .nd-content-last-item {
    width: calc(100% - 110px);
    padding: 115px 14px 80px 14px;
  }

  .nd-content-item-box-title {
    font-size: 13px;
  }

  .nd-content-item-box-text {
    font-size: 10px;
  }

  /* 맵맵맵맵맵!! */

  .nd-map-box {
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .nd-main-map-box {
    width: 100%;
    position: initial;
    padding: 48px 37px;
  }

  .nd-main-map-title-box {
    margin-bottom: 14px;
  }

  .nd-main-map-title {
    font-size: 13px;
  }

  .nd-nd-main-map-text-box {
    margin-bottom: 10px;
  }

  .nd-main-map-text {
    font-size: 10px;
  }

  .nd-main-map-detail-box {
    display: flex;
    justify-content: flex-start;
    margin-top: 13px;
  }

  .nd-main-map-detail {
    font-size: 10px;
  }

  .nd-main-map-img {
    width: 8px;
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .nd-content-top-left-company-title-box,
  .nd-content-bottom-left-company-title-box {
    width: auto;
  }
}
