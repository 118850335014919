.slick-dots {
  bottom: 32px !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slick-dots li button:before {
  color: #fff !important;
}
