.authError-wrapper {
  margin-top: 12px;
  margin-bottom: 12px;
  color: red;
  font-weight: 500;
  text-align: center;
  animation: shake 0.3s ease-in;
  animation-fill-mode: forwards;
}

@keyframes shake {
  0% {
    transform: translate(-30px);
  }
  25% {
    transform: translate(15px);
  }
  50% {
    transform: translate(-10px);
  }
  75% {
    transform: translate(5px);
  }
  100% {
    transform: translate(0px);
  }
}
