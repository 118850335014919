/* 헤더 부분 */

.nd-header {
  /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5); */
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: #ffffff;
  height: 73px;
}

.nd-header-hambuger-menu-container.active {
  opacity: 1;
  transform: translateY(0);
  display: block;
}

.nd-business-header.active {
  transform: translateY(0);
  height: 110px;
  opacity: 1;
}

.nd-header-main-logo {
  width: 120px;
}

.nd-header-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.nd-header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nd-header-menus {
  display: flex;
}

.nd-header-menu-text {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
  margin-left: 55px;
  position: relative;
}

.nd-header-menu-text.active {
  border-bottom: 1px solid #000;
}

.nd-header-menu-text:first-child {
  margin-left: 0;
}

.nd-header-menu-text.last {
  margin-right: 56px;
}

.nd-header-language-box {
  /* display: none; */
  display: flex;
  align-items: center;
  margin-left: 75px;
}

.nd-header-language.active {
  font-size: 15px;
  letter-spacing: -0.38px;
  color: #000000;
  border-bottom: 2px solid #000000;
}

.nd-header-language {
  color: #cccccc;
  margin-right: 0;
  border: 0;
}

.nd-header-language + .nd-header-language {
  margin-left: 22px;
}

.nd-header-hambuger {
  margin-left: 40.5px;
  cursor: pointer;
}

/* 햄버거 메뉴 나오기!! */

.nd-business-header {
  border-top: 1px solid #d3d3d3;
  background-color: #ffffff;
  transition: 0.7s all ease-in-out;
  transform: translateY(-440px);
  height: 0;
  opacity: 0;
  position: relative;
  z-index: 1;
  top: 73px;
}

.nd-business-header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px 0;
  box-sizing: border-box;
  /* padding-right: 250px; */
}

.nd-business-header-box {
  display: flex;
  justify-content: flex-end;
}

.nd-business-header-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 78px;
  display: none;
}

.nd-header-menu-text.active .nd-business-header-menu {
  display: block;
}

.nd-business-header-menu-item {
  box-sizing: border-box;
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  color: #212121;
  text-align: center;
  white-space: nowrap;
}

.nd-business-header-menu-item:hover {
  color: #6481fa;
}

.nd-business-header-menu-item.active {
  color: #6481fa;
}

.nd-header-hambuger-menu-container {
  position: absolute;
  z-index: 1;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  top: 70px;
  transition: 0.7s all ease-in-out;
  opacity: 0;
  transform: translateY(-440px);
}

.main .nd-header-hambuger-menu-box {
  min-height: calc(100vh - 70px);
}

.nd-header-hambuger-menu-box {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 118px 0;
  box-sizing: border-box;
  display: none;
}

.active .nd-header-hambuger-menu-box {
  display: block;
}

.nd-header-hambuger-menu-close {
  position: absolute;
  right: 0;
  top: 30px;
  cursor: pointer;
}

.nd-header-hambuger-mobile-menu-box {
  display: none;
}

.nd-header-hambuger-menu {
  display: flex;
  justify-content: center;
}

.nd-header-hambuger-menu-title-box {
  margin-left: 120px;
  text-align: center;
  padding-bottom: 6px;
}

.nd-header-hambuger-mobile-menu-inner .nd-header-hambuger-menu-title-box {
  text-align: left;
}

.nd-header-hambuger-menu-title-box:first-child {
  margin-left: 0;
}

.nd-header-hambuger-menu-title {
  color: #ffffff;
  font-size: 30px;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 8px;
}

.nd-header-hambuger-menu-text-box {
  text-align: center;
  margin-top: 50px;
}

.nd-header-hambuger-menu-text {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 50px;
}

.nd-header-hambuger-menu-text:last-child {
  margin-bottom: 0;
}

.nd-mobile-br {
  display: none;
}

.nd-header-hambuger-mobile-menu-inner {
  display: none;
}

.pc-only {
  display: block;
}

.mobile-only {
  display: none;
}

/* 미디어 쿼리 */

@media all and (max-width: 1024px) {
  .mobile-only {
    display: block;
  }

  .pc-only {
    display: none;
  }

  .nd-mobile-br {
    display: block;
  }

  .nd-pc-br {
    display: none;
  }

  .nd-header-container {
    padding: 16px;
    box-sizing: border-box;
  }

  .nd-header {
    height: 60px;
  }

  .nd-header-main-logo {
    width: 100px;
    height: auto;
  }

  .nd-header-box {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .nd-header-menu-text,
  .nd-header-language-box {
    display: none;
  }

  .nd-header-hambuger {
    position: absolute;
    top: 5px;
    right: 0;
  }

  .nd-header-main-hambuger {
    width: 22px;
    height: auto;
  }

  /* 햄버거 나오기 */
  .nd-header-hambuger-menu-container {
    display: none;
  }

  .nd-header-hambuger-mobile-menu-inner {
    display: block;
  }

  .nd-header-hambuger-mobile-menu-inner.active {
    transform: translateX(0);
    display: block;
  }

  .nd-header-hambuger-mobile-menu-inner {
    position: fixed;
    z-index: 110;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    top: 0;
    transition: 0.7s all ease-in-out;
    display: block;
    transform: translateX(1024px);
  }
  .nd-header-hambuger-menu-box {
    padding: 5% 0;
  }

  .nd-header-hambuger-menu-close {
    display: none;
  }

  .nd-header-hambuger-mobile-menu-box {
    display: block;
  }

  .nd-header-hambuger-mobile-menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
    border-bottom: 1px solid #f2f1f1;
    padding: 4% 6%;
    box-sizing: border-box;
  }

  .nd-header-hambuger-menu-moblie-close {
    width: 10px;
  }

  .nd-header-hambuger-mobile-menu-language-container {
    display: flex;
  }

  .nd-header-hambuger-mobile-menu-kr-language.active,
  .nd-header-hambuger-mobile-menu-en-language.active {
    color: #000000;
    border-bottom: 1px solid #000000;
  }

  .nd-header-hambuger-mobile-menu-kr-language,
  .nd-header-hambuger-mobile-menu-en-language {
    color: #cccccc;
    border: 0;
    font-size: 6px;
    letter-spacing: -0.38px;
  }

  .nd-header-hambuger-mobile-menu-en-language {
    margin-left: 10px;
  }

  .nd-header-hambuger-menu {
    display: block;
  }

  .nd-header-hambuger-menu-title-box {
    margin-left: 0;
    padding: 6% 6%;
    box-sizing: border-box;
    border-bottom: 1px solid #f2f1f1;
  }

  .nd-header-hambuger-menu-title-box:last-child {
    border: 0;
  }

  .nd-header-hambuger-menu-title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    border-bottom: 0;
  }

  .nd-header-hambuger-menu-text-box {
    text-align: left;
    margin-top: 9%;
    margin-bottom: 9%;
  }

  .nd-header-hambuger-menu-text {
    color: #000000;
    font-size: 6px;
    margin-bottom: 7%;
  }

  .nd-header-hambuger-mobile-menu-text {
    padding: 0 6%;
    color: #000000;
    font-size: 12px;
    margin-bottom: 7%;
  }

  .nd-header-hambuger-menu-text:last-child {
    margin-bottom: 0;
  }
}
