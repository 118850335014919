/* 팝업 */
.popup-bg {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  /* -webkit-backdrop-filter: blur(12.3px);
  backdrop-filter: blur(12.3px); */
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}

.popup-bg.open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  z-index: 999;
}

.popup {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%) scale(0.7);
  width: 430px;
  height: 640px;
  background-color: #fff;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.popup > div {
  width: 100%;
}

.popup > div > img {
  max-width: 100%;
  height: 640px;
}

.open .popup {
  transform: translateX(-50%) scale(1);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.popup-bottom {
  height: 40px;
  background-color: #4a4a4a;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-bottom .check-txt {
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 6px;
  display: inline-block;
  cursor: pointer;
}

.popup-bottom .close {
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.64;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
}

.inp-check {
  display: inline-block;
  vertical-align: middle;
}

.popup-bottom .inp-check input[type='checkbox'] {
  display: none;
}

.check_box {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
  background: 0 0;
  width: 15px;
  height: 15px;
  border: 1px solid #979797;
  background-color: #fff;
}

.check_box.active,
.popup-bottom .inp-check input[type='checkbox']:checked + span {
  border: 1px solid #121d30;
  background: #121d30 url('/images/ico-check_on.png') no-repeat center;
  background-size: 20px auto;
}

.popup-bottom .inp-check input[type='checkbox']:checked + span + .check-txt {
  color: #fff;
}

/* 태블릿 */
@media (max-width: 1024px) {
  .popup {
    width: 320px;
    height: 540px;
  }

  .popup > div > img {
    max-width: 100%;
    height: 540px;
  }
}

/* 모바일 */
@media (max-width: 767px) {
}
