.nd-product-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-product-title-box {
  margin: 86px 0 52px 0;
  text-align: center;
}

.nd-product-title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1.25px;
  color: #000000;
}

.nd-consultant-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.nd-consultant-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.75px;
  color: #000000;
}

.nd-consultant-text-box {
  display: flex;
  align-items: center;
}

.nd-consultant-text {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #cccccc;
}

.nd-consultant-text-star {
  margin: 5px 5px 0 0;
  color: #ff0000;
}

.nd-consultant-form-container {
  border: 1px solid #d7d7d7;
}

.nd-consultant-form {
  margin-bottom: 45px;
}

.nd-consultant-form-box {
  display: flex;
  border-bottom: 1px solid #d7d7d7;
}

.nd-consultant-form-box:last-child {
  border-bottom: 0;
}

.nd-consultant-form-text-box {
  display: flex;
  align-items: center;
  background-color: #fdfdfd;
  padding: 20px 0 20px 24px;
  box-sizing: border-box;
  border-right: 1px solid #d7d7d7;
  width: 12%;
}

.nd-consultant-form-input-box {
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  width: 88%;
}

.nd-consultant-form-input {
  font-size: 18px;
  width: 100%;
  outline: none;
  border: 1px solid #d7d7d7;
  padding: 10px;
  box-sizing: border-box;
}

.nd-consultant-form-input::placeholder {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #000000;
}

.nd-consultant-form-tel-input {
  font-size: 18px;
  width: 15%;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #d7d7d7;
  outline: none;
}

.nd-consultant-form-tel-input::placeholder,
.nd-consultant-form-email-input::placeholder {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  text-align: center;
  color: #000000;
}

.nd-consultant-span {
  margin: auto 14px;
}

.nd-consultant-form-email-input {
  font-size: 18px;
  width: 16%;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid #d7d7d7;
  outline: none;
}

.nd-consultant-form-email {
  margin: auto 6px;
}

.nd-consultant-form-email-select {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #cccccc;
  padding-left: 5px;
  width: 16%;
  box-sizing: border-box;
  border: 1px solid #d7d7d7;
  outline: none;
  appearance: none;
  background-image: url('/images/select-arrow.svg');
  background-position: 90% 50%;
  background-repeat: no-repeat;
}

select::-ms-expand {
  display: none; /* 화살표 없애기 for IE10, 11*/
}

.nd-consultant-form-company-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdfdfd;
  padding: 20px 0;
  box-sizing: border-box;
  border-right: 1px solid #d7d7d7;
  width: 12%;
}

.nd-consultant-form-company-input {
  font-size: 18px;
  width: 34.5%;
  border: 1px solid #d7d7d7;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

.nd-consultant-form-company-input::placeholder,
.nd-consultant-form-position-input::placeholder {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #000000;
}

.nd-consultant-form-position-input {
  font-size: 18px;
  border: 1px solid #d7d7d7;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  width: 16%;
}

.nd-consultant-form-textarea-text-box {
  display: flex;
  background-color: #fdfdfd;
  padding: 20px 0 20px 24px;
  box-sizing: border-box;
  width: 12%;
}

.nd-consultant-form-textarea-box {
  padding: 10px;
  box-sizing: border-box;
  width: 89%;
  border-left: 1px solid #d7d7d7;
}

.nd-consultant-form-textarea {
  resize: none;
  width: 100%;
  border: 1px solid #d7d7d7;
  outline: none;
  padding: 10px;
  font-size: 18px;
  box-sizing: border-box;
}

.nd-consultant-form-textarea::placeholder {
  font-size: 18px;
  color: #000000;
}

.nd-user-textarea-box {
  width: 100%;
  margin-bottom: 28px;
}

.nd-user-textarea {
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  border: 1px solid #d7d7d7;
  outline: none;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #cccccc;
  overflow-y: auto;
  height: 140px;
  white-space: pre-wrap;
}

.nd-user-agree-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}

.nd-user-checkbox {
  width: 18px;
  height: 18px;
  /* margin-bottom: 6px; */
  margin-right: 12px;
  outline: none;
}

.nd-user-agree {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #262626;
}

.nd-inquiry-button-box {
  display: flex;
  justify-content: center;
  margin-bottom: 93px;
}

.nd-inquiry-button {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
  padding: 10px 48px;
  box-sizing: border-box;
  border: 1px solid #6793fb;
}

@media all and (max-width: 1024px) {
  .nd-business-header {
    display: none;
  }

  .nd-business-box {
    padding: 60px 0;
  }

  .nd-business-box-title {
    font-size: 16px;
  }

  .nd-product-title-box {
    margin: 31px 0 14px 0;
  }

  .nd-product-title {
    font-size: 16px;
  }

  .nd-consultant-title {
    font-size: 10px;
  }

  .nd-product-container {
    padding: 0 16px;
    box-sizing: border-box;
  }

  .nd-consultant-title-box {
    display: block;
    margin-bottom: 9px;
  }

  .nd-consultant-text-box {
    margin-top: 4px;
  }

  .nd-consultant-text-star,
  .nd-consultant-text {
    font-size: 10px;
  }

  .nd-consultant-form-box {
    display: block;
  }

  .nd-consultant-form-text-box,
  .nd-consultant-form-textarea-text-box {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #d7d7d7;
    padding: 6px;
    align-items: center;
  }

  .nd-consultant-form-text {
    font-size: 6px;
  }

  .nd-consultant-form-input-box {
    width: 100%;
    padding: 3px;
  }

  .nd-consultant-form-input {
    padding: 6px;
    font-size: 6px;
  }

  .nd-consultant-form-input::placeholder {
    font-size: 6px;
  }

  .nd-consultant-form-tel-input,
  .nd-consultant-form-email-input,
  .nd-consultant-form-company-input,
  .nd-consultant-form-position-input {
    padding: 0;
    width: 26%;
    font-size: 6px;
  }

  .nd-consultant-form-tel-input::placeholder,
  .nd-consultant-form-email-input::placeholder,
  .nd-consultant-form-company-input::placeholder,
  .nd-consultant-form-position-input::placeholder,
  .nd-consultant-form-textarea::placeholder {
    font-size: 6px;
  }

  .nd-consultant-span {
    margin: auto 4px;
  }

  .nd-consultant-form-email {
    margin: auto 4px;
    font-size: 6px;
  }

  .nd-consultant-form-email-select {
    font-size: 6px;
    width: 28%;
  }

  .nd-consultant-form-company-box {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    padding: 6px 0 6px 15px;
    justify-content: flex-start;
  }

  .nd-consultant-form-company-input {
    width: 58%;
    font-size: 6px;
  }

  .nd-consultant-form-position-input {
    width: 28%;
    font-size: 6px;
  }

  .nd-consultant-form-company-input::placeholder,
  .nd-consultant-form-position-input::placeholder {
    font-size: 6px;
    text-align: center;
  }

  .nd-consultant-form-textarea-box {
    padding: 3px;
    width: 100%;
    border: 0;
  }

  .nd-user-textarea-box {
    margin-bottom: 9px;
  }

  .nd-user-textarea {
    font-size: 6px;
  }

  .nd-user-agree-box {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
  }

  .nd-user-checkbox {
    width: 10px;
    margin-right: 4px;
    margin-bottom: 0;
  }

  .nd-user-agree {
    font-size: 6px;
  }

  .nd-inquiry-button-box {
    margin-bottom: 33px;
  }

  .nd-inquiry-button {
    padding: 3px 16px;
    font-size: 6px;
  }
}
