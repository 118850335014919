.nd-about-content-container {
  max-width: 1072px;
  margin: 0 auto;
}

.nd-about-content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 86px 0 60px 0;
}

.nd-about-content-title-box {
  margin-bottom: 56px;
}

.nd-about-content-title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1.25px;
  color: #000000;
}

.nd-about-content-sub-title-box {
  margin-bottom: 50px;
}

.nd-about-content-sub-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  color: #121212;
}

.nd-about-content-text-box {
  margin-bottom: 30px;
  text-align: center;
}

.nd-about-content-text {
  font-size: 25px;
  line-height: 1.4;
  color: #6a6a6a;
}

.nd-about-content-bg {
  margin-bottom: 86px;
}

.nd-about-we-box {
  margin: 0 auto 86px auto;
}

.nd-about-we-title-box {
  margin-bottom: 50px;
  text-align: center;
}

.nd-about-we-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 2.4;
  letter-spacing: -0.63px;
  color: #6180fa;
}

.nd-about-we-item {
  display: flex;
  justify-content: center;
}

.nd-about-we-item-bg {
  width: 100%;
  display: inline-block;
  height: 30%;
}

.nd-about-vlaue {
  margin-bottom: 60px;
}

.nd-about-vlaue-box {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.nd-about-vlaue-title-box {
  margin-right: 80px;
  width: 170px;
  white-space: nowrap;
}

.nd-about-vlaue-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.4;
  color: #000000;
}

.nd-about-value-text-box {
  border-left: 1px solid #cccccc;
  padding-left: 84px;
  box-sizing: border-box;
}

.nd-about-value-text {
  font-size: 24px;
  line-height: 1.4;
  color: #6a6a6a;
}

@media all and (max-width: 1024px) {
  .nd-about-bg-title-box {
    padding: 60px 0;
  }

  .nd-about-bg-title {
    font-size: 16px;
  }

  .nd-about-content-bg-img {
    width: 100%;
  }

  .nd-about-content-box {
    margin: 31px 0 20px 0;
  }

  .nd-about-content-title-box {
    margin-bottom: 30px;
  }

  .nd-about-content-title {
    font-size: 16px;
  }

  .nd-about-content-sub-title-box {
    margin-bottom: 14px;
  }

  .nd-about-content-sub-title {
    font-size: 10px;
  }

  .nd-about-content-text-box {
    margin-bottom: 10px;
  }

  .nd-about-content-text {
    font-size: 10px;
  }

  .nd-about-content-bg {
    margin-bottom: 30px;
  }

  .nd-about-we-box {
    margin-bottom: 30px;
    padding: 0 50px;
    box-sizing: border-box;
  }

  .nd-about-we-title-box {
    margin-bottom: 20px;
  }

  .nd-about-we-title {
    font-size: 16px;
  }

  .nd-about-vlaue-box {
    margin-bottom: 30px;
  }

  .nd-about-vlaue-title-box {
    align-items: center;
    margin-right: 0;
    margin-bottom: 10px;
    width: auto;
  }

  .nd-about-vlaue-box {
    flex-direction: column;
  }

  .nd-about-value-text-box {
    padding-left: 0;
    border-left: 0;
    text-align: center;
  }

  .nd-about-vlaue-title {
    font-size: 10px;
  }

  .nd-about-value-text {
    font-size: 10px;
  }
}
