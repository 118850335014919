.nd-contact-direction-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-contact-direction-title-box {
  text-align: center;
  margin: 86px 0 57px 0;
}

.nd-contact-direction-title {
  font-size: 50px;
  font-weight: bold;
  color: #000000;
}

.nd-contact-direction-map-box {
  display: flex;
  flex-direction: column;
}

.nd-contact-direction-map-explanation-box {
  background-color: #5c7cfa;
  display: flex;
  justify-content: space-between;
  padding: 40px 30px 30px 47px;
  box-sizing: border-box;
  margin-bottom: 60px;
}

.nd-contact-map-explanation-box {
  width: 100%;
}

.nd-contact-direction-map-explanation-title-box {
  margin-bottom: 20px;
}

.nd-contact-direction-map-explanation-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 1;
  color: #ffffff;
}

.nd-contact-direction-map-explanation-text {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #ffffff;
}

.nd-contact-direction-map-explanation-detail-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nd-contact-direction-map-explanation-detail {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  color: #bbc9fd;
}

.nd-contact-direction-map-explanation-detail-img {
  margin-left: 5px;
  vertical-align: text-top;
}

.nd-contact-map-inquiry-box {
  padding: 35px 47px;
  box-sizing: border-box;
  border: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  margin-bottom: 93px;
}

.nd-contact-map-inquiry-title-box {
  margin-right: 50px;
}

.nd-contact-map-inquiry-title {
  font-size: 30px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.nd-contact-map-inquiry-text-box {
  display: flex;
  align-items: center;
}

.nd-contact-map-inquiry-text {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #cccccc;
}

.nd-contact-map-inquiry-text-arrow {
  margin-left: 9px;
  margin-bottom: 5px;
}

@media all and (max-width: 1024px) {
  .nd-contact-direction-title-box {
    margin: 30px 0 20px 0;
  }

  .nd-contact-direction-title {
    font-size: 16px;
  }

  .nd-contact-direction-map-box {
    padding: 0 50px;
    box-sizing: border-box;
  }

  .nd-contact-direction-map-explanation-box {
    padding: 20px 10px;
    margin-bottom: 20px;
  }

  .nd-contact-direction-map-explanation-title-box {
    margin-bottom: 10px;
  }

  .nd-contact-direction-map-explanation-title {
    font-size: 10px;
  }

  .nd-contact-direction-map-explanation-text {
    font-size: 6px;
  }

  .nd-contact-direction-map-explanation-detail {
    font-size: 10px;
  }

  .nd-contact-direction-map-explanation-detail-img {
    margin: 0;
    width: 10px;
    height: auto;
  }

  .nd-contact-map-inquiry-box {
    padding: 10px;
    margin-bottom: 30px;
  }

  .nd-contact-map-inquiry-title-box {
    margin-right: 5px;
  }

  .nd-contact-map-inquiry-title {
    font-size: 10px;
  }

  .nd-contact-map-inquiry-text-box {
    display: flex;
    align-items: center;
  }

  .nd-contact-map-inquiry-text {
    padding-top: 1px;
    box-sizing: border-box;
    font-size: 6px;
  }

  .nd-contact-map-inquiry-text-arrow {
    width: 8px;
    height: auto;
    margin-top: 1px;
  }
}
