.slide-arrow {
  position: absolute;
  border: none;
  outline: none;
  top: 50%;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  z-index: 1000;
  background: transparent;
}

.slide-arrow.left {
  left: 0;
  /* background-image: url('/images/arrow-left.svg'); */
}

.slide-arrow.right {
  right: 0;
  /* background-image: url('/images/arrow-right.svg'); */
}

.slide-arrow img {
  width: 20px;
}

.slide-arrow.pc-only {
  display: inline-flex;
}

.slide-arrow.mo-only {
  display: none;
}

@media (max-width: 1024px) {
  .slide-arrow.left {
    top: initial;
    transform: initial;
    left: initial;
    bottom: 42px;
    right: 0;
  }

  .slide-arrow.right {
    top: initial;
    transform: initial;
    left: initial;
    bottom: 0;
    right: 0;
  }

  .slide-arrow {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .slide-arrow img {
    width: 16px;
  }

  .slide-arrow.pc-only {
    display: none;
  }

  .slide-arrow.mo-only {
    display: inline-flex;
  }
}
