.custom-pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.custom-pagination.introduce-product {
  margin-bottom: 20px;
}

.custom-pagination li {
  border: 1px solid #ddd;
  border-radius: 2px;
  color: #000;
  display: inline-flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.custom-pagination.introduce-product li {
  border: 1px solid #6693fb;
}

.custom-pagination li + li {
  margin-left: 12px;
}

.custom-pagination li:hover,
.custom-pagination li.active {
  border: 1px solid #6693fb;
  color: #fff;
  background-color: #6693fb;
}
