body {
  /* font-family: 'Noto Sans KR', sans-serif; */
  font-family: 'Roboto', 'Noto Sans KR', sans-serif;
}

.pointer {
  cursor: pointer;
}

.content-wrapper {
  min-height: calc(100vh - 100px);
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #121d30; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.upload-editor {
  width: 100%;
  height: 1500px;
}

.pc-only {
  display: flex;
}

.mo-only {
  display: none;
}

input::placeholder,
textarea::placeholder {
  color: #ccc !important;
}

/* 태블릿 */
@media (max-width: 1024px) {
  .pc-only {
    display: none;
  }

  .mo-only {
    display: flex;
  }
}
