/* business 공통 */

.nd-email-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-email-title-box {
  text-align: center;
  margin: 86px 0 56px 0;
}

.nd-email-title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  color: #000000;
}

.nd-email-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 100px;
  box-sizing: border-box;
  border: 1px solid #d3d3d3;
  background-color: #fdfdfd;
  margin-bottom: 300px;
}

.nd-email-text-box {
  margin-left: 50px;
}

.nd-email-text {
  color: #7b7b7b;
  font-size: 20px;
  line-height: 1.3;
  white-space: pre-wrap;
}

@media all and (max-width: 1024px) {
  .nd-email {
    min-height: calc(100vh - 400px);
    box-sizing: border-box;
    padding: 0 10px;
  }

  .nd-business-header {
    display: none;
  }

  .nd-business-box {
    padding: 60px 0;
  }

  .nd-business-box-title {
    font-size: 16px;
  }

  .nd-email-title-box {
    margin: 30px 0;
  }

  .nd-email-title {
    font-size: 16px;
  }

  .nd-email-box {
    padding: 10px 10px;
    box-sizing: border-box;
    margin-bottom: 50px;
  }

  .nd-email-img {
    width: 30px;
    height: auto;
  }

  .nd-email-text-box {
    margin-left: 10px;
  }

  .nd-email-text {
    font-size: 10px;
  }
}
