.cover-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 73px;
  background-attachment: fixed;
}

.cover-bg-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cover-bg-title-box {
  padding: 188px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover-bg-title {
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;
  color: #ffffff;
}

@media all and (max-width: 1024px) {
  .cover-bg-title-box {
    padding: 60px 0;
  }

  .cover-bg-title {
    font-size: 16px;
  }
}
