table thead th:nth-child(1) {
  /* font-size: 20px; */
}

small {
  font-size: 13px !important;
}

table tbody th {
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #f4f4f4;
  border-left: 1px solid #f4f4f4;
}

table td {
  vertical-align: middle;
}

.hide {
  display: none;
}
