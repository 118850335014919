.architect-button-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  box-sizing: border-box;
  padding: 20px;
}

.architect-button-list button {
  flex-shrink: 0;
  padding: 4px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #2e6da4;
  color: #2e6da4;
}

.architect-button-list button.active {
  color: #fff;
  background-color: #2e6da4;
}

.architect-button-list button + button {
  margin-left: 8px;
}
