.nd-business {
  background-image: url('/images/business-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 73px;
}

.nd-business-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-business-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 188px 0;
  box-sizing: border-box;
}

.nd-business-box-title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  color: #ffffff;
}

.nd-business-content-container {
  max-width: 1208px;
  margin: 0 auto;
}

.nd-business-content-title-box {
  text-align: center;
  margin: 86px 0 56px 0;
}

.nd-business-content-title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1.25px;
  color: #000000;
}

.nd-business-content-text-box {
  margin-bottom: 40px;
}

.nd-business-content-text {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
  white-space: pre-wrap;
}

.nd-business-content-bottom-text-box {
  margin: 0 0 94px 24px;
}

@media all and (max-width: 1024px) {
  .nd-business-header {
    display: none;
  }

  .nd-business-box {
    padding: 60px 0;
  }

  .nd-business-box-title {
    font-size: 16px;
  }

  .nd-business-content-container {
    padding: 0 40px;
    box-sizing: border-box;
  }

  .nd-business-content-title-box {
    margin: 40px 0 50px 0;
  }

  .nd-business-content-title {
    font-size: 16px;
  }

  .nd-business-content-text {
    font-size: 6px;
  }

  .nd-business-content-text-box {
    margin-bottom: 15px;
  }

  .nd-business-content-bottom-text-box {
    margin-bottom: 30px;
  }
}
