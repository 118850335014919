/* introduce */

.nd-introduce-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nd-introduce-title-box {
  margin: 86px 0 58px 0;
  text-align: center;
}

.nd-introduce-title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1.25px;
  color: #000000;
}

.nd-introduce-product-box {
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
}

.nd-introduce-product-title-box {
  cursor: pointer;
  border: 1px solid #d7d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 25px 0;
  box-sizing: border-box;
  transition: 0.3s all ease-in-out;
}

.nd-introduce-product-title-box.active {
  border-bottom: 1px solid #6693fb;
  box-shadow: 3px 3px 30px 0 rgb(0 0 0 / 16%);
}

.nd-introduce-product-title-box:hover {
  border-bottom: 1px solid #6693fb;
  box-shadow: 3px 3px 30px 0 rgb(0 0 0 / 16%);
}

.nd-introduce-product-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.63px;
  color: #000000;
}

.nd-introuce-product-menus {
  margin-bottom: 60px;
  display: none;
}

.nd-introuce-product-menus.active {
  display: flex;
}

.nd-introuce-product-menu {
  cursor: pointer;
  padding: 14px 32px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  border-radius: 25px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  display: inline-flex;
  align-items: center;
  height: 48px;
  letter-spacing: -0.5px;
  color: #000000;
  transition: 0.3s all ease-in-out;
  margin-right: 20px;
}

.nd-introuce-product-menu:last-child {
  margin-right: 0;
}

.nd-introuce-product-menu.active {
  background-color: #6693fb;
  color: #ffffff;
}

.nd-introuce-product-menu:hover {
  background-color: #6693fb;
  color: #ffffff;
}

/* 탭키!!!!!! 탭키 하나 배웠다 ㅎㅎ */

.nd-title[data-tab-title] {
  border-bottom: 1px solid #6693fb;
}

[data-tab-content] {
  display: none;
}

.nd-active[data-tab-target] {
  background-color: #6693fb;
  color: #ffffff;
}

.nd-active[data-tab-content] {
  display: block;
}

.nd-introduce-product-view-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.nd-introduce-product-view-number {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
}

.nd-introduce-product-view-number-red {
  color: #ff2400;
}

.nd-introduce-product-view-menus {
  display: flex;
}

.nd-introduce-product-view-menu-box {
  cursor: pointer;
  width: 170px;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
  border: 1px solid #6693fb;
  margin-left: 20px;
}

.nd-introduce-product-view-menu {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
}

.nd-introduce-resin-item {
  display: none;
}

.nd-introduce-resin-item.active {
  display: block;
}

.nd-introduce-resin-item .nd-introduce-product-list {
  border: none;
  margin-bottom: 0;
}

.nd-introduce-product-list {
  border: 1px solid #d7d7d7;
  margin-bottom: 93px;
}

.nd-introduce-product-list-box {
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 32px 17px 30px;
  box-sizing: border-box;
  background-color: #f7f7f7;
}

.nd-introduce-product-list-box:first-child {
  border-top: 0;
}

.nd-introduce-product-list-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
}

.nd-introduce-product-list-minus-bar-box {
  display: flex;
  align-items: center;
}

.nd-introduce-product-list-minus-bar {
  cursor: pointer;
}

.nd-introduce-product-list-plus-bar {
  cursor: pointer;
  display: none;
}

.nd-introduce-product-item {
  border: 1px solid #d7d7d7;
  margin-bottom: 93px;
  display: none;
}

.nd-introduce-product-item.active {
  display: block;
}

.nd-introduce-product-item.border-none {
  border: none;
}

.nd-introduce-product-surfactants-item {
  padding: 30px;
  box-sizing: border-box;
  /* opacity: 0; */
  display: none;
  height: 0;
  transition: 0.1s all ease-in-out;
}

.nd-introduce-product-surfactants-item.active {
  /* opacity: 1; */
  display: block;
  height: auto;
}

.nd-introduce-product-item-list-box {
  border: 1px solid #f7f7f7;
  border-collapse: collapse;
  width: 100%;
}

.nd-introduce-product-item-menu {
  background-color: #fdfdfd;
}

.nd-introduce-product-item-menu-box,
.nd-introduce-product-item-menu-title,
.nd-introduce-product-item-menu-text {
  text-align: center;
  padding: 18px 5px;
  box-sizing: border-box;
  border: 1px solid #f7f7f7;
  vertical-align: middle;
}

.nd-introduce-product-item-menu-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
}

.nd-introduce-product-item-menu-text {
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: #000000;
}

/* PHOTO INITIATOR Table */

.nd-introduce-photo-item-menu-title {
  font-size: 18px;
}

.nd-introduce-photo-item-menu-title:nth-child(1) {
  width: 3%;
}

.nd-introduce-photo-item-menu-title:nth-child(2),
.nd-introduce-photo-item-menu-title:nth-child(3) {
  width: 10%;
}

.nd-introduce-photo-item-menu-title:nth-child(4) {
  width: 10%;
}

.nd-introduce-photo-item-menu-title:nth-child(6) {
  width: 8%;
}

.nd-introduce-photo-item-menu-title:nth-child(7) {
  width: 15%;
}

.nd-introduce-photo-item-menu-title:nth-child(8) {
  width: 18%;
}

.nd-introduce-photo-item-menu-text {
  font-size: 16px;
}

/* EMULSIFYING AGENT Table */

.nd-introduce-agent-item-menu-title:nth-child(2),
.nd-introduce-agent-item-menu-title:nth-child(3) {
  width: 10%;
}

.nd-introduce-agent-item-menu-title:nth-child(4) {
  width: 15%;
}

.nd-introduce-agent-item-menu-title:nth-child(5),
.nd-introduce-agent-item-menu-title:nth-child(7) {
  width: 10%;
}

.nd-introduce-agent-item-menu-title:nth-child(9) {
  width: 10%;
}

/* COATING RESINS */

.nd-coating-title-box {
  margin-bottom: 30px;
}

.nd-coating-title {
  font-size: 25px;
  line-height: 1.3;
}

.nd-coating-text-box {
  margin-top: 30px;
}

.nd-coating-text {
  font-size: 18px;
  line-height: 1.3;
}

/* EPOXY RESINS */

.nd-introduce-epoxy-item-menu-title {
  font-size: 18px;
}

.nd-introduce-epoxy-item-menu-text {
  text-align: left;
}

.nd-introduce-curing-item-menu-text {
  text-align: left;
  width: 30%;
}

/* #nd-surfactants .nd-introduce-product-list-box {
  margin-top: 24px;
} */

#nd-surfactants.nd-introduce-product-item {
  border: none;
  /* border: 1px solid #d7d7d7; */
}

#nd-surfactants .nd-introduce-product-list-box {
  border: 1px solid #d7d7d7;
}

#nd-surfactants .nd-introduce-product-surfactants-item {
  border: 1px solid #d7d7d7;
  border-top: none;
}

.nd-surfactants-item + .nd-surfactants-item {
  margin-top: 36px;
}

#nd-coating .nd-introduce-product-item {
  border: none;
}

#nd-coating .nd-introduce-product-item-box {
  border: 1px solid #d7d7d7;
}

.product-viewer-pc {
  display: block;
  white-space: pre-wrap;
}

.product-viewer-mo {
  display: none;
  white-space: pre-wrap;
}

@media all and (max-width: 1024px) {
  /* business */

  .product-viewer-pc {
    display: none;
  }

  .product-viewer-mo {
    display: block;
  }

  .nd-surfactants-item + .nd-surfactants-item {
    margin-top: 24px;
  }

  .nd-introduce {
    box-sizing: border-box;
    padding: 0 10px;
  }

  .nd-business-box {
    padding: 50px 0 50px 0;
  }

  .nd-business-box-title {
    font-size: 16px;
  }

  .nd-introduce-title-box {
    margin: 30px 0 15px 0;
    text-align: center;
  }

  .nd-introduce-title {
    font-size: 16px;
  }

  .nd-introduce-product-box {
    margin-bottom: 16px;
  }

  .nd-introduce-product-title-box {
    padding: 10px 0;
  }

  .nd-introduce-product-title {
    font-size: 10px;
  }

  .nd-introuce-product-menus {
    margin-bottom: 16px;
    flex-wrap: wrap;
    padding: 0 16px;
    box-sizing: border-box;
  }

  .nd-introuce-product-menu {
    padding: 3px 10px;
    border-radius: 25px;
    font-size: 8px;
    margin-right: 6px;
    height: 24px;
  }

  .nd-introuce-product-menu:nth-child(1),
  .nd-introuce-product-menu:nth-child(2) {
    margin-bottom: 6px;
  }

  .nd-introduce-product-view-box {
    /* padding: 0 16px; */
    box-sizing: border-box;
    margin-bottom: 16px;
  }

  .nd-introduce-product-view-number {
    font-size: 8px;
  }

  .nd-introduce-product-view-menu-box {
    width: 60px;
    padding: 1.5px 0;
    margin-left: 6px;
  }

  .nd-introduce-product-view-menu-box:first-child {
    margin-left: 0;
  }

  .nd-introduce-product-view-menu {
    font-size: 8px;
  }

  .nd-introduce-product-list-minus-bar {
    /* display: none; */
  }

  .nd-introduce-product-item-box {
    overflow-x: scroll;
  }

  .nd-introduce-product-item-list-box {
    width: 100%;
  }

  .nd-introduce-product-item-menu-title,
  .nd-introduce-product-item-menu-text {
    font-size: 8px;
  }

  .nd-introduce-product-list-box,
  .nd-introduce-product-surfactants-item {
    padding: 10px;
  }

  .nd-introduce-product-list-title {
    font-size: 10px;
  }

  .nd-introduce-product-list-minus-bar-box img {
    width: 12px;
  }

  .nd-coating-title {
    font-size: 10px;
  }

  .nd-coating-text {
    font-size: 8px;
  }
}

@media all and (max-width: 768px) {
  .nd-introduce-product-item-list-box {
    width: 800px;
  }
}
